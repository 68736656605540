import {
  DigitalLibraryService,
  digitalLibraryServiceTypes,
  DigitalLibraryTemplate,
  DigitalLibraryTemplateService,
} from "@libry-content/common";
import { LocalizedField } from "@libry-content/localization";
import { DigitalLibraryCustomService } from "@libry-content/types";
import { groq } from "next-sanity";

export const digitalLibraryFilter = groq`_type in ${JSON.stringify(digitalLibraryServiceTypes)}`;

export const resolveConciseDigitalLibraryService = groq`
  _id,
  _type,
  category,
  name,
  teaser,
  image,
  ...template->{code, category, name, teaser, image},
`;

// These type definitions are a bit hairy, being the interface between the type necessary to get the right
// layout in the studio and the one formed by the groq query in order to be useful in the frontend.
export type ResolvedConciseDigitalLibraryService =
  | (Pick<DigitalLibraryCustomService, "_id" | "_type" | "category" | "name" | "teaser" | "image"> & { code: null })
  | (Pick<DigitalLibraryTemplateService, "_id" | "_type"> &
      Pick<DigitalLibraryTemplate, "code" | "category" | "name" | "teaser" | "image">);

// Since there is no way to omit fields in a projection (https://www.sanity.io/answers/is-it-possible-to-exclude-data-from-a-groq-query-request),
// we enumerate all template fields here instead of omitting _id, _type, etc
const templateProjection = `
  code,
  name,
  category,
  description,
  getStarted,
  support,
  teaser,
  image,
  primarySchoolCannotLogInWithFeideText,
  primarySchoolCanLogInWithFeideText,
  canBeUsedWithSelfServiceText
`;

type TemplateFields = "code" | "name" | "category" | "description" | "getStarted" | "support" | "teaser" | "image";

export const resolveDigitalLibraryService = groq`
  ...,
  ...template->{${templateProjection}}
`;

// These type definitions are a bit hairy, being the interface between the type necessary to get the right
// layout in the studio and the one formed by the groq query in order to be useful in the frontend.
export type ResolvedDigitalLibraryService =
  | DigitalLibraryCustomService
  | ((DigitalLibraryService & Pick<DigitalLibraryTemplate, TemplateFields>) & {
      primarySchoolCannotLogInWithFeideText?: LocalizedField<string>;
      primarySchoolCanLogInWithFeideText?: LocalizedField<string>;
      canBeUsedWithSelfServiceText?: LocalizedField<string>;
    });
