import { DocumentType, Expression, Manifestation, Title, Work } from "@biblioteksentralen/cordata";
import { isNonNil } from "@libry-content/common";
import { unique } from "radash";
import { collectionExpressionEntryHasWork, CollectionExpressionEntryWithWork } from "./collections";
import { isValidCoverImage } from "./coverImage";
import { CordataLanguageCode, createLanguageCodeOrder, getSortedLanguageCodeIndex, handleLanguages } from "./languages";
import { havePartTitles, haveSamePartTitlesSctructure, isbdFormattedTitle } from "./titles";
import { ImageManifestation } from "./works";
import { sortByMultiple } from "../../utils/sortByMultiple";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">): DocumentType[] =>
  unique(
    expressions.flatMap(({ manifestations }) =>
      manifestations.flatMap(({ documentType }) => documentType).filter(isNonNil)
    ),
    ({ format }) => format
  );

export type DecoratedManifestation = Manifestation & {
  languages: Expression["languages"];
  collections: CollectionExpressionEntryWithWork[];
};

const getDecoratedWorkManifestations = ({ expressions }: Work): DecoratedManifestation[] =>
  unique(
    expressions.flatMap(({ manifestations, languages, collections = [] }) =>
      manifestations.map((manifestation) => ({
        ...manifestation,
        languages: handleLanguages()(languages),
        collections: unique(
          collections.filter(collectionExpressionEntryHasWork),
          ({ collection }) => collection?.workExpressed?.id
        ),
      }))
    ),
    ({ id }) => id
  );

// TODO: Indicate these in dataplattform -> cordata types, similarly to languages?
const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getPreferredFormatOrderIndex = (
  format: string | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

export type WorkWithAllImages = Work & { allImages: ImageManifestation[] };

type Representation = { representativeLanguageCode?: CordataLanguageCode; representativeFormat?: string };

export type WorkToBeRepresented = WorkWithAllImages & Representation;

const sortByRelevance =
  (workTitle: Title, preferredLanguageCodeOrder: readonly CordataLanguageCode[], preferredFormatOrder: string[]) =>
  <T extends DecoratedManifestation | ImageManifestation>(items: T[]): T[] =>
    sortByMultiple(
      items,
      ({ languages }) => getSortedLanguageCodeIndex(languages[0], preferredLanguageCodeOrder),
      ({ documentType }) => getPreferredFormatOrderIndex(documentType?.format, preferredFormatOrder),
      ({ title }) => (haveSamePartTitlesSctructure(workTitle, title) ? 1 : 2),
      ({ publicationYear }) => (isNaN(Number(publicationYear)) ? 0 : -Number(publicationYear)),
      ({ coverImage }) => (isValidCoverImage(coverImage) ? 1 : 2)
    );

/**
 * Preferred in order: language then published date.
 * Choose an alternative cover image if necessary, preferrably in the same language
 *
 * TODO: Think about parts, series...
 * TODO: Unit test
 */
export const getRepresentativeManifestation = ({
  representativeLanguageCode,
  representativeFormat,
  allImages,
  ...work
}: WorkToBeRepresented): DecoratedManifestation | undefined => {
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguageCode);
  const formatOrder = representativeFormat ? unique([representativeFormat, ...defaultFormatOrder]) : defaultFormatOrder;
  const sort = sortByRelevance(work.title, languageCodeOrder, formatOrder);

  const manifestationsWithLanguages = getDecoratedWorkManifestations(work);

  const sortedManifestations = sort(manifestationsWithLanguages);

  const representativeManifestation = sortedManifestations?.[0];
  if (!representativeManifestation) return undefined;

  if (representativeManifestation.coverImage) return representativeManifestation;

  // We try to always include a cover image if we can, choosing the most relevant one if
  // there is no cover image associated with the representative manifestation.

  // Firstly we try to find an image in the same language as the representative manifestation.
  // We can't just use the first element of sort(allImages) here because allImages is not filtered
  // on holdings, and we might get an image that's of higher priority but different language.
  const imagesInRepresentativeLanguage = allImages.filter(
    ({ languages }) => !!languages[0] && languages[0].code === representativeManifestation.languages[0]?.code
  );

  const mostRelevantCoverImageInRepresentativeLanguage = sort(imagesInRepresentativeLanguage).find(({ coverImage }) =>
    isValidCoverImage(coverImage)
  )?.coverImage;

  if (mostRelevantCoverImageInRepresentativeLanguage) {
    return { ...representativeManifestation, coverImage: mostRelevantCoverImageInRepresentativeLanguage };
  }

  const mostRelevantCoverImage = sort(allImages).find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage;

  return { ...representativeManifestation, coverImage: mostRelevantCoverImage };
};

export const filterManifestationsOnHoldings = <ManifestationType extends Partial<Manifestation>>(
  manifestations: ManifestationType[],
  isilNumbers: string[] | null
): ManifestationType[] =>
  manifestations.filter(({ items }) => !!items?.some(({ library }) => isilNumbers?.includes(library.isilCode)));

export const getFormattedRepresentativeManifestationTitle = (
  work: Work,
  representativeManifestation: Manifestation | undefined
) => {
  const title = representativeManifestation?.title ?? work.title;
  return isbdFormattedTitle(title, havePartTitles(work.title));
};

export const getRelevantManifestations = (work: WorkToBeRepresented) => {
  const manifestationsWithLanguages = getDecoratedWorkManifestations(work);

  return manifestationsWithLanguages.filter(
    ({ languages, documentType }) =>
      !!languages.find(({ code }) => code === work.representativeLanguageCode) &&
      documentType?.format === work.representativeFormat
  );
};
