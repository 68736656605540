type AtLeastOne<T> = [T, ...T[]];

type GetSortIndex<T> = (element: T) => number;

const compare = <T>(indexFunction: GetSortIndex<T>, left: T, right: T) => indexFunction(left) - indexFunction(right);

/**
 * Corresponds to lodash's sortBy called with several comparison functions (no equivalent in ramda)
 */
export const sortByMultiple = <T extends unknown>(array: T[], ...indexFunctions: AtLeastOne<GetSortIndex<T>>): T[] =>
  array.slice().sort((left, right) =>
    indexFunctions.slice(1).reduce(
      // If the previous comparison is 0, it means left and right are equal according to the previous
      // comparison function. That's also the only way it'll be falsy, therefore we can
      // use the or operator || to then apply the next comparison function.
      (previous, indexFunction) => previous || compare(indexFunction, left, right),
      compare(indexFunctions[0], left, right) // Initial comparison
    )
  );
