import { Agent, Work } from "@biblioteksentralen/cordata";
import { getErrorMessage, isNonNil } from "@libry-content/common";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { ContributorRoleLabel, getContributorRoleSortIndex, isContributorRoleLabel } from "./cordata/roles";
import { filterWorkDataOnHoldings, getAllWorkImages } from "./cordata/works";
import {
  isAgent,
  isAgentsResponse,
  isWork,
  isWorksResponse,
  SearchAgentsRequestData,
  SearchCategory,
  SearchWorksByContributorRoleRequestData,
  SearchWorksRequestData,
} from "./types";

export const searchParameterName = "s";

export const sizeParameterNames: Record<SearchCategory, string> = {
  works: "antallVerk",
  events: "antallArrangementer",
  agents: "antallPersoner",
};

// id and type must be included due to check `isWorksResponse()`
const ensureIdAndType = (fields: string[]): string[] => uniq([...fields, "id", "type"]);

const getSearchRequestParams = (data: Record<string, unknown>): RequestInit => ({
  method: "post",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(data),
});

export const defaultSizes = {
  works: 9,
  events: 3,
  agents: 4,
};

// TODO: Import from dataplattform repo?
type Filter =
  | {
      type: "term";
      field: string;
      value: string;
    }
  | {
      type: "terms";
      field: string;
      values: string[];
    };

const getHoldingByIsilNumbersFilter = (isilNumbers: string[]) =>
  ({ type: "terms", field: "libraryId", values: isilNumbers } as const);

const getWorksFilters = (data: SearchWorksRequestData) => {
  const { contributorId, personSubjectId, subjectId, isilNumbers } = data;

  if ([contributorId, personSubjectId, subjectId].filter(isNonNil).length > 1) {
    throw new Error("Can't search for works while specifying more than one filter term");
  }

  const filters: Filter[] = [];

  if (isilNumbers?.length) filters.push(getHoldingByIsilNumbersFilter(isilNumbers));

  if (contributorId) filters.push({ type: "term", field: "contributor_id", value: contributorId });
  else if (personSubjectId) filters.push({ type: "term", field: "person_subject_ids", value: personSubjectId });
  else if (subjectId) filters.push({ type: "term", field: "subject_ids", value: subjectId });
  return filters;
};

export const searchWorks = async <WorkType extends Partial<Work> = Work>(
  searchApiUrl: string,
  data: SearchWorksRequestData
) => {
  const { searchQuery, fields: fieldsData, size = defaultSizes.works, from = 0, isilNumbers } = data;

  const fields = fieldsData ? ensureIdAndType(fieldsData) : undefined;

  const searchBody = {
    from,
    size: Number(size),
    ...(searchQuery ? { query: searchQuery } : {}),
    fields,
    filters: getWorksFilters(data),
  };

  const apiResponse = await fetch(`${searchApiUrl}/search/works`, getSearchRequestParams(searchBody));

  if (!apiResponse.ok) {
    throw new Error(`Could not perform works search: ${apiResponse.statusText}`);
  }

  const responseData: unknown = await apiResponse.json();

  if (!isWorksResponse<WorkType>(responseData)) throw new Error(`Unexpected works search response: ${responseData}`);

  const works = responseData.works
    .map((work) => ({ ...work, allImages: getAllWorkImages(work) }))
    .map(filterWorkDataOnHoldings(isilNumbers));

  return { ...responseData, works };
};

export const searchAgents = async (searchApiUrl: string, data: SearchAgentsRequestData) => {
  const { searchQuery, size = defaultSizes.agents, from = 0 } = data;

  const searchBody = {
    from,
    size: Number(size),
    ...(searchQuery ? { query: searchQuery } : {}),
  };

  const apiResponse = await fetch(`${searchApiUrl}/search/agents`, getSearchRequestParams(searchBody));

  if (!apiResponse.ok) {
    throw new Error(`Could not perform agents search: ${apiResponse.statusText}`);
  }

  const responseData: unknown = await apiResponse.json();

  if (!isAgentsResponse(responseData)) throw new Error(`Unexpected agents search response: ${responseData}`);

  return responseData;
};

const hasWorks = (data: unknown): data is { works: unknown[] } =>
  !!data && typeof data === "object" && Array.isArray(data?.["works"]);

const foundSingleWork = <WorkType extends Partial<Work>>(data: unknown): data is { works: [WorkType] } =>
  hasWorks(data) && data.works.length === 1 && isWork(data.works[0]);

export const getWork = async <WorkType extends Partial<Work> = Work>(
  searchApiUrl: string,
  workId: string,
  isilNumbers: string[] | null
) => {
  try {
    const searchBody = { query: `work:${workId}` };
    const apiResponse = await fetch(`${searchApiUrl}/search/works`, getSearchRequestParams(searchBody));

    if (!apiResponse.ok) {
      throw new Error(`Could not get work: ${apiResponse.statusText}`);
    }

    const responseData: unknown = await apiResponse.json();

    if (!foundSingleWork(responseData)) throw new Error(`Could not get work "${workId}"`);

    const responseWork = responseData.works[0];
    const work = { ...responseWork, allImages: getAllWorkImages(responseWork) };

    return filterWorkDataOnHoldings(isilNumbers)(work);
  } catch (err) {
    console.error(getErrorMessage(err));
  }
};

const hasAgents = (data: unknown): data is { agents: unknown[] } =>
  !!data && typeof data === "object" && Array.isArray(data?.["agents"]);

const foundSingleAgent = (data: unknown): data is { agents: [Agent] } =>
  hasAgents(data) && data.agents.length === 1 && isAgent(data.agents[0]);

export const getAgent = async (searchApiUrl: string, { bibbiId }: { bibbiId?: string }) => {
  if (!bibbiId) return;

  try {
    const filters = [{ type: "term", field: "bibbiId", value: bibbiId }];

    const apiResponse = await fetch(`${searchApiUrl}/search/agents`, getSearchRequestParams({ filters }));

    if (!apiResponse.ok) {
      throw new Error(`Could not get agent: ${apiResponse.statusText}`);
    }

    const responseData: unknown = await apiResponse.json();

    if (foundSingleAgent(responseData)) return responseData.agents[0];
  } catch (err) {
    console.error(`Could not get agent: ${getErrorMessage(err)}`);
  }
};

type ContributorRolesData = { contributor_roles: { key: string }[] };

const hasContributorRoles = (data: unknown): data is ContributorRolesData =>
  typeof data === "object" &&
  Array.isArray(data?.["contributor_roles"]) &&
  !!data?.["contributor_roles"].every((item) => typeof item?.["key"] === "string");

const parseContributorRoles = ({ contributor_roles }: ContributorRolesData) =>
  sortBy(
    contributor_roles.reduce((acc: ContributorRoleLabel[], { key }) => {
      if (isContributorRoleLabel(key)) return [...acc, key];
      console.error(`Received unknown contributor role label "${key}`);
      return acc;
    }, []),
    (roleLabel) => getContributorRoleSortIndex(roleLabel)
  );

export const aggregateContributorRoles = async (searchApiUrl: string, { bibbiId }: { bibbiId?: string }) => {
  if (!bibbiId) return;

  const onError = () => {
    throw new Error(`Could not aggregate contributor roles for agent "${bibbiId}"`);
  };

  try {
    const body = { agentId: bibbiId };
    const apiResponse = await fetch(`${searchApiUrl}/aggregate/contributor-roles`, getSearchRequestParams(body));

    if (!apiResponse.ok) return onError();

    const responseData: unknown = await apiResponse.json();

    if (!hasContributorRoles(responseData)) return onError();

    return parseContributorRoles(responseData);
  } catch (err) {
    console.error(getErrorMessage(err));
  }
};

export const searchContributorRoleWorks = async (
  searchApiUrl: string,
  data: SearchWorksByContributorRoleRequestData
) => {
  const { agentId, roleLabel, fields: fieldsData, size = defaultSizes.works, from = 0, isilNumbers } = data;
  const fields = fieldsData ? ensureIdAndType(fieldsData) : undefined;

  const filters = isilNumbers?.length ? [getHoldingByIsilNumbersFilter(isilNumbers)] : [];
  const searchBody = { agentId, roleLabel, from, size: Number(size), fields, isilNumbers, filters };

  const onError = () => {
    throw new Error(`Could not search works for agent "${agentId}" contributor role "${roleLabel}`);
  };

  try {
    const apiResponse = await fetch(
      `${searchApiUrl}/search/works-by-contributor-role`,
      getSearchRequestParams(searchBody)
    );

    if (!apiResponse.ok) return onError();

    const responseData: unknown = await apiResponse.json();
    if (!isWorksResponse(responseData)) return onError();

    const works = responseData.works
      .map((work) => ({ ...work, allImages: getAllWorkImages(work) }))
      .map(filterWorkDataOnHoldings(isilNumbers));

    return { ...responseData, works };
  } catch (err) {
    console.error(getErrorMessage(err));
  }
};
