import { isProduction } from "./isProduction";

const featureToggles = {
  statistikk: () => queryParam("statistikk") || isEnv("development"),
  sommerles: () => queryParam("sommerles"),
  "advent-calendar": () => queryParam("advent-calendar"),
  maalgruppe: () => queryParam("maalgruppe"),
  "brukertest-anbefalinger": () => queryParam("brukertest-anbefalinger") || isEnv("staging"),
  minside: () => queryParam("minside"),
  search: () => queryParam("search"),
  "custom-palette": () => queryParam("custom-palette") || isEnv("development"),
  "bilder-events": () => queryParam("bilder-events") || isEnv("development"),
};

export type FeatureToggleKey = keyof typeof featureToggles;

export const featureToggleEnabled = (toggleKey: FeatureToggleKey) => featureToggles[toggleKey]();

export const getEnabledFeatureToggles = () =>
  Object.keys(featureToggles).filter((key) => featureToggleEnabled(key as FeatureToggleKey));

type Environment = "development" | "staging" | "production";
const isDevelopment = process.env.NODE_ENV === "development";

/**
 * @param environments feks, isEnv('development', 'staging')
 */
export const isEnv = (...environments: Environment[]): boolean => {
  if (environments.includes("development") && isDevelopment) return true;
  if (environments.includes("production") && isProduction) return true;
  if (environments.includes("staging") && !isDevelopment && !isProduction) return true;
  return false;
};

/**
 * @param queries feks, queryParam('mintoggle'). Kan da skurs på ved å sende med queryparam i url "?ft=mintoggle"
 */
const queryParam = (...queries: string[]): boolean => {
  const flags = getStoredFeatureFlags();
  if (flags.includes("all")) return true; // Kan alltid skru på alle toggles ved å skrive ?ft=all i url
  if (queries.some((query) => flags.includes(query))) return true; // Kan sende med feks ?ft=statistikk i url
  return false;
};

const sessionStorageKey = "feature-toggles";

export const storeFeatureFlags = () => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return; // For SSR in nextjs

  const searchParams = new URLSearchParams(location.search);
  const flags = searchParams.getAll("ft");

  // setter bare feature-flag i localstorage hvis man faktisk sender inn feature-flags i url (uten denne sjekken vil eksisterende feature-flagg ble overskrevet ved reload)
  if (flags.length) {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(flags));
  }
};

const getStoredFeatureFlags = (): string[] => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return []; // For SSR in nextjs

  const storedFlags = sessionStorage.getItem(sessionStorageKey);
  return storedFlags ? JSON.parse(storedFlags) : [];
};
