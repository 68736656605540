var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"64a3970a5597a8a785c6b2fe6599614760a0e5f6"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";
import { sanityConfig } from "@libry-content/common";

const { dataset } = sanityConfig;

const isPreview = () =>
  typeof window !== "undefined" && new URL(window.location.href).searchParams.get("preview") === "true";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.5, // Use tracesSampler for finer control
    // Note: if you want to override the automatic release value, do not set a `release` value here -
    // use the environment variable `SENTRY_RELEASE`, so that it will also get attached to your source maps
    environment: dataset,
    integrations: [
      new CaptureConsole({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],
  });

  // Indicate preview using a tag
  if (isPreview()) Sentry.configureScope((scope) => scope.setTag("libryContent.preview", true));
}
